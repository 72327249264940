import React from "react";

const CashIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 20.25C12.0009 20.9502 10.0317 21.375 7.875 21.375C6.67584 21.375 5.53465 21.2437 4.5 21.0067C3.8349 20.8543 3.50234 20.7782 3.10386 20.4629C2.87658 20.283 2.59293 19.9271 2.46838 19.6654C2.25 19.2066 2.25 18.7616 2.25 17.8718V5.75322C2.25 4.64529 3.42003 3.88432 4.5 4.13168C5.53465 4.36866 6.67584 4.5 7.875 4.5C10.0317 4.5 12.0009 4.07516 13.5 3.375C14.9991 2.67484 16.9683 2.25 19.125 2.25C20.3242 2.25 21.4654 2.38134 22.5 2.61832C23.1651 2.77066 23.4977 2.84683 23.8961 3.16213C24.1234 3.34197 24.4071 3.69793 24.5316 3.95962C24.75 4.41844 24.75 4.86337 24.75 5.75322V17.8718C24.75 18.9797 23.58 19.7407 22.5 19.4933C21.4654 19.2563 20.3242 19.125 19.125 19.125C16.9683 19.125 14.9991 19.5498 13.5 20.25Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M2.25 23.625C3.74911 24.3252 5.7183 24.75 7.875 24.75C10.0317 24.75 12.0009 24.3252 13.5 23.625C14.9991 22.9248 16.9683 22.5 19.125 22.5C21.2817 22.5 23.2509 22.9248 24.75 23.625"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16.3125 11.8125C16.3125 13.3658 15.0533 14.625 13.5 14.625C11.9467 14.625 10.6875 13.3658 10.6875 11.8125C10.6875 10.2592 11.9467 9 13.5 9C15.0533 9 16.3125 10.2592 16.3125 11.8125Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M6.1875 12.9375L6.1875 12.9476"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.8125 10.6787L20.8125 10.6888"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CashIcon;
