import React from "react";

const HotspotIcon = () => {
  return (
    <svg
    className="border border-[#FFFFFF]"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="2" stroke="white" stroke-linecap="round" />
      <path
        d="M3 17.001C1.74418 15.3295 1 13.2516 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 13.2516 20.2558 15.3295 19 17.001"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M6.52779 15C5.57771 13.9385 5 12.5367 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 12.5367 16.4223 13.9385 15.4722 15"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M8.95154 17.8759C9.72223 16.758 10.1076 16.199 10.6078 16.0553C10.8644 15.9816 11.1356 15.9816 11.3922 16.0553C11.8924 16.199 12.2778 16.758 13.0485 17.8759C14.074 19.3633 14.5867 20.1071 14.488 20.727C14.4379 21.0414 14.2938 21.3315 14.076 21.5565C13.6465 22 12.7643 22 11 22C9.23568 22 8.35352 22 7.92399 21.5565C7.70617 21.3315 7.56205 21.0414 7.512 20.727C7.4133 20.1071 7.92605 19.3633 8.95154 17.8759Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HotspotIcon;
