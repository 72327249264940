import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React from "react";
import { toast } from "react-toastify";
import BackButton from "../icons/BackButton";
import CashIcon from "../icons/CashIcon";
import HotspotIcon from "../icons/HotspotIcon";
import { httpReauest } from "../utils/httpRequest";

const PremiumModal = ({ setPreactive, data, setData, id, getUser }) => {
  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const transactio2 = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQAilCqmWO0El0NmJp6lNQKu2Nhu1azwZ1bOE1PDssgCvYCR", // message destination in user-friendly format
        amount: "100000000", // Toncoin in nanotons
      },
    ],
  };
  async function sendDouble(e) {
    console.log(e?._id);
    const response = await httpReauest(
      "POST",
      `/user/premium`,
      { telegramId: id },
      {}
    );
  }
  async function handleStar(params) {
    const response = await httpReauest(
      "POST",
      "/user/pay/star",
      { telegramId: id },
      {}
    ).then((e) => {
      console.log(e);
    });
  }
  async function handleBuyTicket(e) {
    try {
      if (!userFriendlyAddress) {
        return toast.error("Connect Your Wallet First");
      }
      const response = await tonConnectUI.sendTransaction(transactio2);
      // بررسی وضعیت پاسخ
      if (response) {
        await sendDouble(e).then((res) => {
          toast.success("Successfull");
          getUser();
        });
      } else {
        toast.error("Transaction failed. Please try again.");
      }
    } catch (error) {
      console.error("Error sending transaction:", error);
      toast.error(
        "An error occurred while sending the transaction. Please try again."
      );
    }
  }
  return (
    <div className="w-full h-[100vh] flex flex-col items-center bg-[#000000] ">
      <div className="w-full relative mb-8 prompt text-[20px] font-[600] bg-[#151515] border px-5 py-3 pb-5 rounded-b-3xl border-[#FFFFFF]/30 flex items-center justify-center">
        <h3 className="linear">SQUARE PREMIUM</h3>
        <button
          onClick={() => {
            setPreactive(false);
          }}
          className="absolute top-2 left-3"
        >
          <BackButton />{" "}
        </button>
      </div>
      <div className="flex gap-4 justify-start items-start mx-8 mt-2">
        <CashIcon />
        <div className="flex flex-col w-[90%]">
          <h4 className="text-[20px]"> 1. Triple Rewards (3X Points)</h4>
          <p className="text-[12px] text-[#FFFFFF]/50">
            Premium users earn 3X points on all activities and transactions,
            accelerating their progress and unlocking exclusive rewards faster.
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-start items-start mx-8 mt-2">
        <p className="text-[32px]">$</p>
        <div className="flex flex-col w-[90%]">
          <h4 className="text-[20px]"> 2. Exclusive Badge </h4>
          <p className="text-[12px] text-[#FFFFFF]/50">
            Enjoy a green badge next to your name, showcasing your premium
            status. Additionally, gain 6 months of unlimited access to all
            advanced features of the platform
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-start items-start mx-8 mt-2">
        <p className="text-[20px]">X2</p>
        <div className="flex flex-col w-[90%]">
          <h4 className="text-[20px]"> 3. Enhanced Marketing Commissions</h4>
          <p className="text-[12px] text-[#FFFFFF]/50">
            Earn 30% direct commission from your referrals (compared to 10% for
            standard users)
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-start items-start mx-8 mt-2">
        <HotspotIcon />
        <div className="flex flex-col w-[90%]">
          <h4 className="text-[20px]"> 4. Monthly Airdrops</h4>
          <p className="text-[12px] text-[#FFFFFF]/50">
            Having a Blue Badge is not absolutely mandatory, but having it will
            guarantee the highest level of income.
          </p>
        </div>
      </div>
      <div className="w-full absolute bottom-10 px-4 ">
        <button
          onClick={handleBuyTicket}
          className=" flex justify-center items-center gap-1 w-[100%] py-3 border border-[#FFFFFF]/30 rounded-[12px] text-[12px] bg-[#151515]"
        >
          SUBSCRIBE FOR <img src="/img/ton.png" alt="" /> 1
        </button>
        <button
          onClick={handleStar}
          className=" flex justify-center items-center mt-2 gap-1 w-[100%] py-3 border border-[#FFFFFF]/30 rounded-[12px] text-[12px] bg-[#151515]"
        >
          SUBSCRIBE FOR <img src="/img/star.png" alt="" /> 450
        </button>
      </div>
    </div>
  );
};

export default PremiumModal;
