import React from 'react'

const FreindCart = ({freind}) => {
  return (
    <div className="flex px-6 py-2 w-full text-[12px] bg-[#151515] border border-[#FFFFFF]/30 rounded-[12px] items-center justify-between">
      <div className="flex items-center justify-center gap-1 ">
        <div className="text-[8px] border border-[#FFFFFF] rounded py-2">
          PROFILE
        </div>
        <div className="flex flex-col items-start">
          <p>{freind?.username}</p>
          <p>{freind?.balance} SQUARE</p>
        </div>
        
      </div>
      <div></div>
    </div>
  )
}

export default FreindCart
