import React from "react";

const ProfileIcon = ({ location }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {location.pathname === "/profile" ? (
        <>
          <path
            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
            stroke="url(#paint0_linear_12_191)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26 15 3.41 18.13 3.41 22"
            stroke="url(#paint1_linear_12_191)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_12_191"
              x1="12"
              y1="2"
              x2="12"
              y2="12"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#66CCFF" />
              <stop offset="1" stop-color="#3D7A99" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_12_191"
              x1="12"
              y1="15"
              x2="12"
              y2="22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#66CCFF" />
              <stop offset="1" stop-color="#3D7A99" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <path
            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26003 15 3.41003 18.13 3.41003 22"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </>
      )}
    </svg>
  );
};

export default ProfileIcon;
