import React, { useContext, useState } from "react";
import { IoMdDoneAll } from "react-icons/io";
import FreindCart from "../components/FreindCart";
import LeaderCart from "../components/LeaderCart";
import { userContext } from "../context/userContext";

const FreindsPage = () => {
  const { data, setData, id, setId } = useContext(userContext);
  const freinds = data?.invitedPepole;
  const [copy, setCopy] = useState(false);
  const textToCopy = `https://t.me/Squareonton_bot?start=${data?.user?.ref}`;
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopy(true);
      setTimeout(() => {
        setCopy(false);
      }, 5000);
    });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full relative mb-8 prompt text-[20px] font-[500] bg-[#151515] border px-5 py-3  rounded-b-3xl border-[#FFFFFF]/30 flex items-center justify-center">
        <h3 className="">{freinds?.length} FRIENDS</h3>
      </div>
      {freinds ? (
        <div className="flex w-full px-4 flex-col items-center">
          {freinds?.map((e) => (
            <FreindCart freind={e} />
          ))}
        </div>
      ) : (
        <p className="text-[16px]">You havnt invited any friends yet</p>
      )}
      <div className="flex flex-col items-center w-full px-4 absolute bottom-20 gap-4">
        <div className="grid grid-cols-2 w-full">
          <div className="flex justify-center items-center gap-1">
            <span className="w-[20px] h-[20px] border-[6px] border-[#FFFFFF]"></span>
            <div className="flex flex-col items-start">
              <p className="text-[12px] mb-[-5px]">500 SQUARE</p>
              <p className="text-[10px] text-[#FFFFFF]/50">for a friend</p>
            </div>
          </div>
          <div className="flex justify-center gap-1 items-center ">
            <span className="w-[20px] h-[20px] border-[6px] border-[#FFFFFF]"></span>
            <div className="flex flex-col items-start">
              <p className="text-[12px] mb-[-5px]">2000 SQUARE</p>
              <p className="text-[10px] text-[#FFFFFF]/50">with TG Premium</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full items-center text-[16px]">
          <button className="w-[78%] rounded-[12px] border-[#FFFFFF]/30 border bg-[#151515] py-2">
            INVITE A FRIEND
          </button>
          {copy ? (
            <button className="w-[20%] flex justify-center items-center rounded-[12px] border-[#FFFFFF]/30 border bg-[#151515] py-2">
              <IoMdDoneAll size={25} />
            </button>
          ) : (
            <button
              onClick={handleCopy}
              className="w-[20%] rounded-[12px] border-[#FFFFFF]/30 border bg-[#151515] py-2"
            >
              COPY
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FreindsPage;
