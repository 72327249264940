import React from "react";
import { IoMdDoneAll } from "react-icons/io";
import { Link } from "react-router-dom";

const HomeCard = ({ tasks }) => {
  return (
    <div className="w-full flex items-center justify-between text-[12px] rounded-xl bg-[#151515] border-[#FFFFFF]/30 border px-4 py-2">
      <div className="flex flex-col items-start justify-start">
        <p>{tasks.content}</p>
        <p className="text-[#FFFFFF]/60">{tasks.point} SQUARE</p>
      </div>
      {tasks?.done ? (
        <IoMdDoneAll size={20}/>
      ) : (
        <Link to={tasks?.url} className="border border-[#FFFFFF] p-1 rounded-[7px]">
          DO
        </Link>
      )}
    </div>
  );
};

export default HomeCard;
