import React from "react";

const EarnIcon = ({ location }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {location.pathname === "/earn" ? (
        <>
          {" "}
          <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stop-color="#66CCFF" />
              <stop offset="100%" stop-color="#3D7A99" />
            </linearGradient>
          </defs>
          <path
            d="M6.29469 16C2.53045 6.25 7.86313 1.9375 11 1C14.1369 1.9375 19.4696 6.25 15.7053 16C15.1369 15.6875 13.4 15.0625 11 15.0625C8.6 15.0625 6.86313 15.6875 6.29469 16Z"
            stroke="url(#gradient1)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13 8C13 6.89543 12.1046 6 11 6C9.89543 6 9 6.89543 9 8C9 9.10457 9.89543 10 11 10C12.1046 10 13 9.10457 13 8Z"
            stroke="url(#gradient1)"
            stroke-width="1.5"
          />
          <path
            d="M16.5 14.5576C17.9421 14.6908 19.7078 15.0822 20.9814 16C20.9814 16 21.5044 11.0642 17 10"
            stroke="url(#gradient1)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.5 14.5576C4.05794 14.6908 2.29216 15.0822 1.01858 16C1.01858 16 0.495552 11.0642 5 10"
            stroke="url(#gradient1)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.5 18C8.5 18 8.91667 20.5 11 21C13.0833 20.5 13.5 18 13.5 18"
            stroke="url(#gradient1)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M6.29469 16C2.53045 6.25 7.86313 1.9375 11 1C14.1369 1.9375 19.4696 6.25 15.7053 16C15.1369 15.6875 13.4 15.0625 11 15.0625C8.6 15.0625 6.86313 15.6875 6.29469 16Z"
            stroke="#FCFCFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13 8C13 6.89543 12.1046 6 11 6C9.89543 6 9 6.89543 9 8C9 9.10457 9.89543 10 11 10C12.1046 10 13 9.10457 13 8Z"
            stroke="#FCFCFF"
            stroke-width="1.5"
          />
          <path
            d="M16.5 14.5576C17.9421 14.6908 19.7078 15.0822 20.9814 16C20.9814 16 21.5044 11.0642 17 10"
            stroke="#FCFCFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.5 14.5576C4.05794 14.6908 2.29216 15.0822 1.01858 16C1.01858 16 0.495552 11.0642 5 10"
            stroke="#FCFCFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.5 18C8.5 18 8.91667 20.5 11 21C13.0833 20.5 13.5 18 13.5 18"
            stroke="#FCFCFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </>
      )}
    </svg>
  );
};

export default EarnIcon;
