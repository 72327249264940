import { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./app.css";

import { userContext } from "./context/userContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";

import { TonConnectUIProvider } from "@tonconnect/ui-react";
import Navbur from "./components/Navbur";
import ProfilePage from "./pages/ProfilePage";
import LeadersPage from "./pages/LeadersPage";
import EarnPage from "./pages/EarnPage";
import FreindsPage from "./pages/FreindsPage";
import Loading from "./components/Loading";

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");

 

  return (
    <>
      <TonConnectUIProvider manifestUrl="https://squareonton.com/tonconnect-manifest.json">
      <ToastContainer />
      <BrowserRouter>
        <userContext.Provider value={{ data, setData, id, setId }}>
          <Routes>
            <Route
              path="/"
              element={<Home setLoading={setLoading} loading={loading} />}
            />
            <Route
              path="/profile"
              element={
                <ProfilePage setLoading={setLoading} loading={loading} />
              }
            />
            <Route
              path="/leaders"
              element={
                <LeadersPage setLoading={setLoading} loading={loading} />
              }
            />
            <Route
              path="/earn"
              element={<EarnPage setLoading={setLoading} loading={loading} />}
            />
            <Route
              path="/friends"
              element={
                <FreindsPage setLoading={setLoading} loading={loading} />
              }
            />
          </Routes>
          {!loading && <Navbur />}
        </userContext.Provider>
      </BrowserRouter>
      </TonConnectUIProvider>
    </>
  );
};

export default App;
