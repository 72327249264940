import React from "react";

const LeadersIcon = ({ location }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {location.pathname === "/leaders" ? (
        <>
          {" "}
          <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stop-color="#66CCFF" />
              <stop offset="100%" stop-color="#3D7A99" />
            </linearGradient>
          </defs>
          <path
            d="M17.8162 5.38891V5.38891C17.9154 4.10028 16.8965 3 15.604 3H8.39597C7.10353 3 6.08464 4.10028 6.18376 5.38891V5.38891M17.8162 5.38891L17.3835 11.0147C17.1671 13.8278 14.8214 16 12 16V16M17.8162 5.38891V5.38891C19.7681 5.38891 21.2744 7.10607 21.0201 9.04128L20.9259 9.75862C20.6822 11.6133 19.1014 13 17.2308 13V13M6.18376 5.38891L6.61652 11.0147C6.83291 13.8278 9.17862 16 12 16V16M6.18376 5.38891V5.38891C4.23192 5.38891 2.72561 7.10607 2.97987 9.04128L3.07412 9.75862C3.31781 11.6133 4.8986 13 6.76923 13V13M12 16V18M12 18L14.2138 19.3283C15.2031 19.9218 15.6977 20.2186 15.6948 20.505C15.6939 20.5922 15.6703 20.6777 15.6262 20.7529C15.4813 21 14.9044 21 13.7507 21H10.2493C9.09558 21 8.51872 21 8.37384 20.7529C8.32973 20.6777 8.30606 20.5922 8.30518 20.505C8.30229 20.2186 8.79694 19.9218 9.78624 19.3283L12 18Z"
            stroke="url(#gradient1)"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M11.1929 7.38529C11.5024 6.75823 11.6571 6.4447 11.8897 6.3921C11.9623 6.37567 12.0377 6.37567 12.1103 6.3921C12.3429 6.4447 12.4976 6.75823 12.8071 7.38529L12.8722 7.51718C12.9631 7.7015 13.0086 7.79367 13.082 7.85868C13.1061 7.8801 13.1323 7.89913 13.1602 7.9155C13.2447 7.96518 13.3464 7.97996 13.5498 8.00952L13.6954 8.03067C14.3874 8.13122 14.7334 8.1815 14.8552 8.3864C14.8933 8.4504 14.9166 8.52209 14.9234 8.59624C14.9452 8.83365 14.6949 9.0777 14.1941 9.56579L14.0888 9.66846C13.9416 9.81193 13.868 9.88367 13.8289 9.97354C13.816 10.0031 13.806 10.0339 13.799 10.0655C13.7779 10.1612 13.7952 10.2625 13.83 10.4651L13.8548 10.61C13.9731 11.2992 14.0322 11.6438 13.875 11.8231C13.8259 11.8791 13.7649 11.9234 13.6965 11.9528C13.4774 12.0469 13.1679 11.8842 12.549 11.5588L12.4188 11.4904C12.2369 11.3947 12.1459 11.3469 12.0483 11.3374C12.0162 11.3343 11.9838 11.3343 11.9517 11.3374C11.8541 11.3469 11.7631 11.3947 11.5812 11.4904L11.451 11.5588C10.8321 11.8842 10.5226 12.0469 10.3035 11.9528C10.2351 11.9234 10.1741 11.8791 10.125 11.8231C9.96784 11.6438 10.0269 11.2992 10.1452 10.61L10.17 10.4651C10.2048 10.2625 10.2221 10.1612 10.201 10.0655C10.194 10.0339 10.184 10.0031 10.1711 9.97354C10.132 9.88367 10.0584 9.81193 9.91118 9.66846L9.80585 9.56579C9.30512 9.0777 9.05475 8.83365 9.07658 8.59624C9.0834 8.52209 9.10669 8.4504 9.14476 8.3864C9.26665 8.1815 9.61265 8.13122 10.3046 8.03067L10.4502 8.00952C10.6536 7.97996 10.7553 7.96518 10.8398 7.9155C10.8677 7.89913 10.8939 7.8801 10.918 7.85868C10.9914 7.79367 11.0369 7.70151 11.1278 7.51718L11.1929 7.38529Z"
            stroke="url(#gradient1)"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M17.8162 5.38891V5.38891C17.9154 4.10028 16.8965 3 15.604 3H8.39597C7.10353 3 6.08464 4.10028 6.18376 5.38891V5.38891M17.8162 5.38891L17.3835 11.0147C17.1671 13.8278 14.8214 16 12 16V16M17.8162 5.38891V5.38891C19.7681 5.38891 21.2744 7.10607 21.0201 9.04128L20.9259 9.75862C20.6822 11.6133 19.1014 13 17.2308 13V13M6.18376 5.38891L6.61652 11.0147C6.83291 13.8278 9.17862 16 12 16V16M6.18376 5.38891V5.38891C4.23192 5.38891 2.72561 7.10607 2.97987 9.04128L3.07412 9.75862C3.31781 11.6133 4.8986 13 6.76923 13V13M12 16V18M12 18L14.2138 19.3283C15.2031 19.9218 15.6977 20.2186 15.6948 20.505C15.6939 20.5922 15.6703 20.6777 15.6262 20.7529C15.4813 21 14.9044 21 13.7507 21H10.2493C9.09558 21 8.51872 21 8.37384 20.7529C8.32973 20.6777 8.30606 20.5922 8.30518 20.505C8.30229 20.2186 8.79694 19.9218 9.78624 19.3283L12 18Z"
            stroke="white"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M11.1929 7.38529C11.5024 6.75823 11.6571 6.4447 11.8897 6.3921C11.9623 6.37567 12.0377 6.37567 12.1103 6.3921C12.3429 6.4447 12.4976 6.75823 12.8071 7.38529L12.8722 7.51718C12.9631 7.7015 13.0086 7.79367 13.082 7.85868C13.1061 7.8801 13.1323 7.89913 13.1602 7.9155C13.2447 7.96518 13.3464 7.97996 13.5498 8.00952L13.6954 8.03067C14.3874 8.13122 14.7334 8.1815 14.8552 8.3864C14.8933 8.4504 14.9166 8.52209 14.9234 8.59624C14.9452 8.83365 14.6949 9.0777 14.1941 9.56579L14.0888 9.66846C13.9416 9.81193 13.868 9.88367 13.8289 9.97354C13.816 10.0031 13.806 10.0339 13.799 10.0655C13.7779 10.1612 13.7952 10.2625 13.83 10.4651L13.8548 10.61C13.9731 11.2992 14.0322 11.6438 13.875 11.8231C13.8259 11.8791 13.7649 11.9234 13.6965 11.9528C13.4774 12.0469 13.1679 11.8842 12.549 11.5588L12.4188 11.4904C12.2369 11.3947 12.1459 11.3469 12.0483 11.3374C12.0162 11.3343 11.9838 11.3343 11.9517 11.3374C11.8541 11.3469 11.7631 11.3947 11.5812 11.4904L11.451 11.5588C10.8321 11.8842 10.5226 12.0469 10.3035 11.9528C10.2351 11.9234 10.1741 11.8791 10.125 11.8231C9.96784 11.6438 10.0269 11.2992 10.1452 10.61L10.17 10.4651C10.2048 10.2625 10.2221 10.1612 10.201 10.0655C10.194 10.0339 10.184 10.0031 10.1711 9.97354C10.132 9.88367 10.0584 9.81193 9.91118 9.66846L9.80585 9.56579C9.30512 9.0777 9.05475 8.83365 9.07658 8.59624C9.0834 8.52209 9.10669 8.4504 9.14476 8.3864C9.26665 8.1815 9.61265 8.13122 10.3046 8.03067L10.4502 8.00952C10.6536 7.97996 10.7553 7.96518 10.8398 7.9155C10.8677 7.89913 10.8939 7.8801 10.918 7.85868C10.9914 7.79367 11.0369 7.70151 11.1278 7.51718L11.1929 7.38529Z"
            stroke="white"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </>
      )}
    </svg>
  );
};

export default LeadersIcon;
