import React from "react";

const BackButton = () => {
  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0833 1H19.2083C23.1434 1 26.3333 4.18997 26.3333 8.125C26.3333 12.06 23.1434 15.25 19.2083 15.25H1"
        stroke="#FFFAF5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.74996 10.5C5.74996 10.5 1.00001 13.9983 1 15.25C0.999989 16.5018 5.75 20 5.75 20"
        stroke="#FFFAF5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BackButton;
