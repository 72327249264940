import React from "react";

const HomeIcon = ({location}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     {location.pathname === '/' ? (<> <path
        d="M7.06165 2.82633L1.23911 7.92134C0.739798 8.35826 1.07458 9.13428 1.76238 9.13428C2.18259 9.13428 2.52324 9.44892 2.52324 9.83705V13.0806C2.52324 15.871 2.52324 17.2662 3.46176 18.1331C4.40029 19 5.91082 19 8.93189 19H11.0681C14.0892 19 15.5997 19 16.5382 18.1331C17.4768 17.2662 17.4768 15.871 17.4768 13.0806V9.83705C17.4768 9.44893 17.8174 9.13428 18.2376 9.13428C18.9254 9.13428 19.2602 8.35826 18.7609 7.92134L12.9383 2.82633C11.5469 1.60878 10.8512 1 10 1C9.14878 1 8.45307 1.60878 7.06165 2.82633Z"
        stroke="url(#paint0_linear_31_113)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 14H10.009"
        stroke="url(#paint1_linear_31_113)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_31_113"
          x1="10"
          y1="1"
          x2="10"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#66CCFF" />
          <stop offset="1" stop-color="#3D7A99" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_31_113"
          x1="10.0045"
          y1="14"
          x2="10.0045"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#66CCFF" />
          <stop offset="1" stop-color="#3D7A99" />
        </linearGradient>
      </defs></>) : (<><path
    d="M7.06165 2.82633L1.23911 7.92134C0.739798 8.35826 1.07458 9.13428 1.76238 9.13428C2.18259 9.13428 2.52324 9.44892 2.52324 9.83705V13.0806C2.52324 15.871 2.52324 17.2662 3.46176 18.1331C4.40029 19 5.91082 19 8.93189 19H11.0681C14.0892 19 15.5997 19 16.5382 18.1331C17.4768 17.2662 17.4768 15.871 17.4768 13.0806V9.83705C17.4768 9.44893 17.8174 9.13428 18.2376 9.13428C18.9254 9.13428 19.2602 8.35826 18.7609 7.92134L12.9383 2.82633C11.5469 1.60878 10.8512 1 10 1C9.14878 1 8.45307 1.60878 7.06165 2.82633Z"
    stroke="url(#paint0_linear_31_113)"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 14H10.009"
    stroke="url(#paint1_linear_31_113)"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <defs>
    <linearGradient
      id="paint0_linear_31_113"
      x1="10"
      y1="1"
      x2="10"
      y2="19"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FFFFFF" /> 
      <stop offset="1" stop-color="#CCCCCC" /> 
    </linearGradient>
    <linearGradient
      id="paint1_linear_31_113"
      x1="10.0045"
      y1="14"
      x2="10.0045"
      y2="15"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FFFFFF" /> 
      <stop offset="1" stop-color="#CCCCCC" /> 
    </linearGradient>
  </defs></>) }
    </svg>
  );
};

export default HomeIcon;
